<script>
/**
 * Remote select counterparty
 * @author Evgeny Shevtsov, info@sitespring.ru
 * @homepage http://sitespring.ru
 * @licence Proprietary
 */
import BaseStore from "../../../models/BaseStore";
import compact from "lodash/compact";
import isInteger from "lodash/isInteger";
import OrderItem from "../../../models/OrderItem";

export default {
    name: "ProductToItemSelect",
    data() {
        return {
            innerValue: '',
            store: new BaseStore([], {
                entityName: 'product',
                fetchParams: {
                    limit: 8,
                    sort: '-name',
                    fields: 'id,name,price,price_action,vendor_code',
                    expand: 'sellingPrice,vendorText',
                    filterText: ''
                }
            })
        }
    },
    methods: {
        fetchOptions(query) {
            // remove filters
            this.store.setOption("filters", []);
            this.store.setOption("fetchParams", {
                ...this.store.getOption("fetchParams"),
                filterText: query
            });
            this.store.fetch();
        },
        getTitleString(model){
            return compact([
                model.vendor_code && `${model.vendor_code}`,
                model.vendorText && `(${model.vendorText})`,
                model.name
            ]).join(", ");
        },
        getMetaString(model) {
            return compact([
                model.price && `цена  ${this.$Formatter.asPrice(model.price)}`,
                model.price_action && `по акции ${this.$Formatter.asPrice(model.price_action)}`
            ]).join(", ");
        },
        onChange() {
            let item, product;
            let val = this.innerValue;
            if (!val) {
                return;
            }
            if (isInteger(val)) {
                product = this.store.find({id: val});
                item = new OrderItem({title: product.name, product_id: product.id, cost: product.sellingPrice, total: product.sellingPrice});
            } else {
                item = new OrderItem({title: val});
            }
            this.$emit('itemselect', item);

            //auto clear after emit
            this.innerValue = null;
        }
    }
}
</script>


<template>
    <el-select v-model="innerValue"
               remote
               clearable
               filterable
               allow-create
               @change="onChange"
               :loading="store.loading"
               :remote-method="fetchOptions"
               placeholder="Название, артикул, код продукта...">
        <template slot="prefix">
            <i class="el-input__icon el-icon-magic-stick"></i>
        </template>
        <el-option v-for="option in store.models"
                   :label="option.name"
                   :value="option.id"
                   :key="option.id">
            <div class="name">{{ getTitleString(option) }}</div>
            <div class="metas">{{ getMetaString(option) }}</div>
        </el-option>
    </el-select>
</template>


<style scoped lang="scss">
.el-select-dropdown {
    &__item {
        .name {
            line-height: 20px;
        }

        .metas {
            line-height: 12px;
            font-size: 12px;
            color: silver;
            font-weight: 300;
        }
    }
}
</style>