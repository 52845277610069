<script>
/**
 * @author Evgeny Shevtsov, info@sitespring.ru
 * @homepage http://sitespring.ru
 * @licence Proprietary
 */
import Order from "../../../models/Order";
import PrimaryActionButton from "./PrimaryActionButton";

export default {
    name: "Card",
    components: {PrimaryActionButton},
    props: {
        id: Number | String
    },


    data() {
        return {
            statuses: [],
            isModelFetched: false,
            model: new Order({id: this.id}, [], {
                fetchParams: {
                    expand: 'statusText,payStatusText,items,deliveryText,counterparty,isConfirmationRequired,courierName,items.code'
                }
            })
        }
    },


    watch: {
        $route: function (route) {
            // update data only when model`s id changed
            if (route.params.id != this.model.id) {
                this.prepareData();
            }
        }
    },


    methods: {
        async prepareData() {
            this.isModelFetched = false;
            this.model.set("id", this.$route.params.id);
            try {
                await this.model.fetch();
                this.isModelFetched = true;
            } catch (e) {
                this.$message.error("Ошибка загрузки данных.");
            }
        },
        async fetchStatuses() {
            this.statuses = await Order.getStatuses();
        },
        onStatusChange(status) {
            this.model.sendStatusChange(status, {
                params: {
                    fields: 'status, updated_at',
                    expand: 'statusText,isConfirmationRequired'
                }
            }).then(() => {
                this.$message.success("Статус заказа изменен");
            });
        }
    },

    created() {
        this.prepareData();
        this.fetchStatuses();
    }
}
</script>


<template>
    <div v-loading="model.loading || model.saving"
         class="humanize-container--long">

        <el-row>
            <el-col :span="18">
                <el-page-header @back="$router.push({name:'order-list'})"
                                title="">
                    <template v-slot:content>
                        {{ `Заказ № ${model.number}` }}, {{ model.statusText }}, {{ model.payStatusText }}
                        <span class="header-metas">
                            <i class="el-icon-time"></i> {{ model.$.updated_at|datetime('LLLL') }}
                        </span>
                    </template>
                </el-page-header>
            </el-col>
            <el-col :span="6" class="text-right">
                <primary-action-button :order-model="model"></primary-action-button>
            </el-col>
        </el-row>

        <el-menu :router="true" :default-active="$route.name" mode="horizontal">
            <el-menu-item index="order-basic" :route="{name:'order-basic',params:{model}}">
                Номенклатура
            </el-menu-item>
            <el-menu-item index="order-balance" :route="{name:'order-balance', params:{model}}">
                История платежей
            </el-menu-item>
            <el-menu-item index="order-log" :route="{name:'order-log', params:{model}}">
                Журнал
            </el-menu-item>
        </el-menu>

        <router-view :model="model"
                     v-if="isModelFetched"></router-view>
    </div>
</template>


<style lang="scss" scoped>
.header-metas {
    display: block;
    font-weight: 200;
    font-size: smaller;
    color: gray;
}

.el-menu {
    margin: 0 0 20px;
}
</style>