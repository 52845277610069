<script>
    /**
     * @author Evgeny Shevtsov, info@sitespring.ru
     * @homepage http://sitespring.ru
     * @licence Proprietary
     */
    import AddressInput from "../../../components/AddressInput";
    import Formatter from "../../../utils/Formatter";
    import Order from "../../../models/Order";
    import DeliveryAddressSelect from "./DeliveryAddressSelect";
    import CourierSelect from "../../../components/CourierSelect";

    export default {
        name: "DeliveryFormSection",
        components: {
            CourierSelect,
            DeliveryAddressSelect,
            AddressInput
        },
        props: {
            model: {
                type: Order,
                required: true
            }
        },
        data() {
            return {
                Formatter,
                deliveryTypes: []
            }
        },
        computed: {
            isCourierVisible: vm => vm.model.delivery_type === Order.DELIVERY_COURIER,
            isAddressVisible: vm => vm.model.delivery_type !== Order.DELIVERY_PICKUP,
            isSelectListVisible: vm => vm.isAddressVisible && vm.model.counterparty_id
        },
        methods: {
            async fetchDeliveryTypes() {
                this.deliveryTypes = await Order.getDeliveryTypes();
            },
            onDeliveryCostChange(newVal, oldVal) {
                this.model.total = this.model.total - (oldVal || 0) + (newVal || 0);
            },
            onAddressSelect(address) {
                this.model.delivery_address = address.address;
            }
        },
        created() {
            this.fetchDeliveryTypes();
        }
    }
</script>


<template>
    <el-row :gutter="20">
        <el-col :sm="12">
            <el-form-item label="Способ доставки"
                          :error="model.errors.delivery_type"
                          :show-message="!!model.errors.delivery_type">
                <el-select v-model="model.delivery_type"
                           clearable>
                    <el-option v-for="deliveryType in deliveryTypes"
                               :label="deliveryType.label"
                               :value="deliveryType.value"
                               :key="deliveryType.value">{{deliveryType.label}}
                    </el-option>
                </el-select>
            </el-form-item>
        </el-col>
        <el-col :sm="12">
            <el-form-item label="Дата отгрузки"
                          :error="model.errors.delivery_date"
                          :show-message="!!model.errors.delivery_date">
                <el-date-picker v-model="model.delivery_date"
                                :value-format="Formatter.dateSubmitFormat"
                                :format="Formatter.dateDisplayFormat"
                                placeholder="13.05.2020"
                                clearable></el-date-picker>
            </el-form-item>
        </el-col>
        <el-col v-if="isCourierVisible">
            <el-form-item label="Назначить курьера"
                          :error="model.errors.courier_id"
                          :show-message="!!model.errors.courier_id">
                <courier-select
                        v-model="model.courier_id"></courier-select>
            </el-form-item>
        </el-col>
        <el-col>
            <address-input v-if="isAddressVisible"
                           label="Адрес доставки"
                           prop-name="delivery_address"
                           :model="model">
            </address-input>
            <delivery-address-select v-if="isSelectListVisible"
                                     :model="model"
                                     @select="onAddressSelect"
                                     style="margin: -22px 0 22px"></delivery-address-select>
        </el-col>
    </el-row>
</template>


<style scoped>

</style>