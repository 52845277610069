<script>
    /**
     * @author Evgeny Shevtsov, info@sitespring.ru
     * @homepage https://sitespring.ru
     * @licence Proprietary
     */
    import vue from 'vue';
    import Order from "../../../models/Order";
    import find from "lodash/find";

    export default {
        name: "DeliveryAddressSelect",
        props: {
            model: {
                type: Order,
                required: true
            }
        },
        data() {
            return {
                isSelectAddressMode: false,
                isLoading: false,
                addresses: [],
                selectedAddress: null
            }
        },
        watch: {
            selectedAddress() {
                this.$emit('select', find(this.addresses, {id: this.selectedAddress}));
                this.isSelectAddressMode = false;
            }
        },
        methods: {
            async onSelectClick() {
                vue.set(this.model, 'loading', true);
                try {
                    this.addresses = await this.model.fetchDeliveryAddresses();
                    this.isSelectAddressMode = true;
                } catch (e) {
                    this.$message.error("Ошибка сети");
                } finally {
                    vue.set(this.model, 'loading', false);
                }
            }
        }
    }
</script>


<template>
    <div>
        <a href="#" @click.prevent="onSelectClick">Выбрать</a>
        <el-dialog :visible.sync="isSelectAddressMode" title="Адрес доставки">
            <div v-for="address in addresses" :key="address.id" class="item-select">
                <el-radio v-model="selectedAddress" :label="address.id">{{address.name}}<br>
                    <address>{{address.address}}</address>
                    <span class="notes">{{address.notes}}</span>
                </el-radio>
            </div>
        </el-dialog>
    </div>
</template>


<style scoped lang="scss">
    .item-select {
        margin: 0 0 20px;
    }

    address {
        font-size: larger;
        font-weight: normal;
        white-space: normal;
    }

    .notes {
        color: gray;
        font-weight: normal;
    }
</style>