<script>
/**
 * The orders list
 * @author Evgeny Shevtsov, info@sitespring.ru
 * @homepage http://sitespring.ru
 * @licence Proprietary
 */
import LazyList from "../../components/LazyList";
import BaseStore from "../../models/BaseStore";
import Order from "../../models/Order";
import OrderListItem from "./ListItem";
import StoreSorter from "../../components/StoreSorter";
import ListStoreFilters from "@/views/order/ListStoreFilters";

export default {
  name: "List",
  components: {
    ListStoreFilters,
    StoreSorter,
    OrderListItem,
    LazyList
  },
  data() {
    return {
      store: new BaseStore([], {
        model: Order,
        autoSort: true,
        autoFilter: true,
        sorter: {
          property: 'created_at',
          direction: BaseStore.SORT_DESC
        },
        fetchParams: {
          limit: 25,
          fields: 'id,counterparty_id,number,delivery_date,manager_notes,total,client_name,client_phone,delivery_address',
          expand: 'statusText,payStatusText,counterpartyName,courierName'
        }
      }),
      sortOptions: [
        {
          id: 'created_desc',
          title: 'Недавно созданные',
          property: 'created_at',
          direction: BaseStore.SORT_DESC
        },
        {
          id: 'updated_desc',
          title: 'Недавно измененные',
          property: 'updated_at',
          direction: BaseStore.SORT_DESC
        },
        {
          id: 'delivery_desc',
          title: 'Дата отгрузки',
          property: 'delivery_date',
          direction: BaseStore.SORT_DESC
        },
      ]
    }
  },
  methods: {}
}
</script>


<template>
  <div class="humanize-container">
    <lazy-list :store="store"
               create-route="order-create"
               details-route="order-basic"
               :is-checkable="false"
               :is-refreshable="true"
               filter-placeholder="поиск: Номер заказа, email, имя или телефон контрагента...">
      <template v-slot:toolbar>
        <div class="toolbar">
          <store-sorter :store="store" :sorter-options="sortOptions"></store-sorter>
          <list-store-filters :store="store"></list-store-filters>
        </div>
      </template>
      <template v-slot:item="{record}">
        <order-list-item :order="record"></order-list-item>
      </template>
    </lazy-list>
  </div>
</template>

<style scoped lang="scss">

</style>