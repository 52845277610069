<script>
    /**
     * Common usage address input
     * @author Evgeny Shevtsov, info@sitespring.ru
     * @homepage http://sitespring.ru
     * @licence Proprietary
     */

    import BaseModel from "../models/BaseModel";


    export default {
        name: "AddressInput",
        props: {
            label: String,
            model: {
                type: BaseModel,
                required: true
            },
            propName: {
                type: String,
                required: true
            }
        }
    }
</script>


<template>
    <el-form-item :label="label"
                  :error="model.errors[propName]"
                  :show-message="!!model.errors[propName]">
        <el-input type="textarea"
                  v-model="model[propName]"
                  :maxlength="128"
                  :show-word-limit="true"
                  placeholder="123456, г.Санкт-Петербург, ул. Ленина д.6, оф. 666, корпус 1"
                  :autosize="{ minRows: 2}"></el-input>
    </el-form-item>
</template>


<style scoped>

</style>