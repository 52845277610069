<script>
/**
 * @author Evgeny Shevtsov, info@sitespring.ru
 * @homepage http://sitespring.ru
 * @licence Proprietary
 */

import Order from "../../../models/Order";
import OrderPayment from "../../../models/OrderPayment";
import Formatter from "../../../utils/Formatter";
import CourierIncomeTimeline from "@/views/order/card/CourierIncomeTimeline";

export default {
    name: "Balance",
    components: {CourierIncomeTimeline},
    props: {
        model: {
            type: Order,
            required: true
        }
    },
    data() {
        return {
            Formatter,
            types: [],
            isFormVisible: false,
            formTitle: '',
            editableRecord: this.createRecord(),
            store: this.model.getPayments({
                autoLoad: true,
                fetchParams: {
                    limit: 0,
                    sort: '-created_at',
                    expand: 'typeText'
                }
            })
        }
    },
    methods: {
        createRecord(attr) {
            return new OrderPayment({
                ...attr,
                order_id: this.model.id,
                created_at: new Date(this.model.delivery_date)
            });
        },

        async fetchTypes() {
            this.types = await OrderPayment.getTypes();
        },

        onAddClick() {
            this.formTitle = 'Добавить платеж';
            this.editableRecord = this.createRecord({
                mode: OrderPayment.MODE_INCOME
            });
            this.isFormVisible = true;
        },

        onRefundClick() {
            this.formTitle = 'Возврат средств';
            this.editableRecord = this.createRecord({
                mode: OrderPayment.MODE_REFUND
            });
            this.isFormVisible = true;
        },

        async onSaveClick() {
            try {
                await this.editableRecord.save({
                    params: {
                        expand: 'typeText,balance,payStatusText'
                    }
                });
                this.store.add(this.editableRecord);
                this.store.replace([this.editableRecord, ...this.store.models]);
                this.model.balance = this.editableRecord.balance;
                this.model.payStatusText = this.editableRecord.payStatusText;
                this.isFormVisible = false;
            } catch (e) {
                if (this.editableRecord.fatal) {
                    this.$message.error("Ошибка сети");
                }
            }
        },

        /**
        * @param {OrderPayment} $payment
        * */
        async onDeleteClick($payment) {
            await $payment.delete();
            this.store.remove($payment);
            this.model.fetch();
        },

        /**
         * @param {OrderPayment} $payment
         * */
        handlePaymentCreateByCourierConfirmation($payment) {
            this.store.add($payment);
            // Обновляем данные Заказа
            this.model.set($payment.getOrder().attributes);
            this.model.sync();
        }
    },
    created() {
        this.fetchTypes();
    }
}
</script>


<template>
    <div v-loading="store.loading">
        <div class="toolbar">
            <div class="toolbar-item">
                <span class="balance">
                Баланс: {{ model.balance|currency }}
                    </span>/<span class="total">{{ model.total|currency }}</span>
            </div>
            <div class="toolbar-item">
                <el-button @click="onAddClick" type="primary" icon="el-icon-plus">Платеж</el-button>
                <el-button @click="onRefundClick" type="danger" icon="el-icon-minus">Возврат</el-button>
            </div>
        </div>

        <el-timeline>
            <el-timeline-item v-for="record in store.models"
                              :key="record.id"
                              :timestamp="Formatter.asDateTime(record.$.created_at)"
                              :type="record.$.value > 0 ? 'success' : 'danger'">

                <el-popconfirm title="Удалить платеж из истории?"
                               confirm-button-text="Удалить"
                               cancel-button-text="Отмена"
                               @confirm="onDeleteClick(record)">
                    <el-link slot="reference"
                             class="action-link"
                             :type="record.$.value > 0 ? 'success' : 'danger'">{{ record.typeText }}: {{ record.$.value|currency }}
                    </el-link>
                </el-popconfirm>
                <div class="notes">{{ record.$.comment }}</div>
            </el-timeline-item>
        </el-timeline>

        <courier-income-timeline :orderModel="model"
                                 @paymentcreated="handlePaymentCreateByCourierConfirmation"></courier-income-timeline>

        <el-dialog :visible.sync="isFormVisible"
                   :v-loading="editableRecord.saving"
                   width="420px"
                   :title="formTitle">
            <el-form>
                <el-row :gutter="20">
                    <el-col :span="24">
                        <el-form-item label="Способ платежа"
                                      required
                                      :error="editableRecord.errors.type"
                                      :show-message="!!editableRecord.errors.type">
                            <el-select v-model="editableRecord.type">
                                <el-option v-for="item in types"
                                           :key="item.type"
                                           :value="item.type"
                                           :label="item.typeText">{{ item.typeText }}
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="Сумма, руб"
                                      required
                                      :error="editableRecord.errors.value"
                                      :show-message="!!editableRecord.errors.value">
                            <el-input-number v-model="editableRecord.value"
                                             placeholder="1234.56"
                                             :min="0"
                                             :controls="false"></el-input-number>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="Фактическая дата платежа"
                                      :error="editableRecord.errors.created_at"
                                      :show-message="!!editableRecord.errors.created_at">
                            <el-date-picker v-model="editableRecord.created_at"
                                            type="datetime"
                                            :format="$Formatter.dateTimeDisplayFormat"
                                            :value-format="$Formatter.dateTimeSubmitFormat"></el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-form-item label="Комментарий"
                              :error="editableRecord.errors.comment"
                              :show-message="!!editableRecord.errors.comment">
                    <el-input v-model="editableRecord.comment"
                              type="textarea"
                              show-word-limit
                              :maxlength="255"
                              :rows="{min:3}"></el-input>
                </el-form-item>
            </el-form>
            <div class="toolbar">
                <el-button type="primary" @click="onSaveClick">Сохранить</el-button>
            </div>
        </el-dialog>
    </div>

</template>


<style scoped lang="scss">
.el-timeline {
    padding: 0 0 0 40px;
}

.balance {
    padding: 0;
    font-size: larger;
    font-weight: 500;
}
</style>