<script>
    /**
     * The order overview
     * @author Evgeny Shevtsov, info@sitespring.ru
     * @homepage http://sitespring.ru
     * @licence Proprietary
     */
    import Order from "../../../models/Order";
    import Formatter from "../../../utils/Formatter";

    export default {
        name: "Overview",
        props: {
            model: {
                type: Order,
                required: true
            }
        },
        data() {
            return {}
        },
        computed: {
            counterpartyTitle: vm => vm.model.client_name || 'Контрагент',
            counterpartyRoute: vm => !vm.model.counterparty_id ? false : {
                name: 'counterparty-edit',
                params: {
                    id: vm.model.counterparty_id
                }
            },
            courierTitle: vm => vm.model.courierName && `, ${vm.model.courierName}` || '',
            courierRoute: vm => (vm.model.courierName && vm.model.courier_id) ?
                {
                    name: 'courier-edit',
                    params: {
                        id: vm.model.courier_id
                    }
                }
                : false
        },
        methods: {
            priceFormatter(row, column, cellValue, index) {
                return Formatter.asCurrency(cellValue);
            }
            ,
            percentFormatter(row, column, cellValue, index) {
                return Formatter.asPercent(cellValue);
            }
            ,
        }
    }
</script>


<template>
    <div class="order-overview humanize-container--long">
        <section class="humanize-container">
            <el-row :gutter="20" type="flex" align="top">
                <el-col :span="12">
                    <div class="section-header">
                        <el-icon class="el-icon-user"></el-icon>&nbsp;

                        <router-link :to="counterpartyRoute"
                                     v-if="counterpartyRoute"
                                     title="Перейти в карточку контрагента">{{counterpartyTitle}}
                        </router-link>
                        <span v-else>{{counterpartyTitle}}</span>
                    </div>
                    <div class="section-content">
                        {{model.$.client_email}}<br>
                        {{model.$.client_phone}}
                        <p class="notes">{{model.$.client_notes}}</p>
                    </div>

                </el-col>
                <el-col :span="12">
                    <div class="section-header">
                        <el-icon class="el-icon-truck"></el-icon>&nbsp;
                        <span v-if="model.$.delivery_date">{{model.$.delivery_date|date }}
                            <router-link :to="courierRoute"
                                         v-if="courierRoute"
                                         title="Перейти в карточку курьера">{{courierTitle}}
                            </router-link>
                        </span>
                        <span v-else>Доставка</span>
                    </div>
                    <div class="section-content">
                        {{model.$.deliveryText}}<br>
                        <address>{{model.$.delivery_address}}</address>
                    </div>
                </el-col>
            </el-row>
        </section>


        <section>
            <el-table :data="model.items"
                      show-header>
                <el-table-column
                        type="index"
                        width="50"></el-table-column>
                <el-table-column prop="title"
                                 label="Номенклатура">
                    <template v-slot:default="{row:item}">
                        <router-link v-if="item.product_id" :to="{name:'product-basic',params:{id:item.product_id}}">{{item.title}}</router-link>
                        <span v-else>{{item.title}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="cost"
                                 label="Цена"
                                 align="right"
                                 :width="100"
                                 :formatter="priceFormatter">
                </el-table-column>
                <el-table-column prop="count"
                                 label="Кол-во"
                                 align="center"
                                 :width="100"></el-table-column>
                <el-table-column prop="discount_percent"
                                 label="Скидка,%"
                                 align="center"
                                 :width="100"
                                 :formatter="percentFormatter">
                </el-table-column>
                <el-table-column prop="total"
                                 label="Стоимость"
                                 align="right"
                                 :width="100"
                                 :formatter="priceFormatter">
                </el-table-column>
                <template v-slot:append>
                    <div class=""></div>
                </template>
            </el-table>
            <el-row :gutter="40">
                <el-col :span="14">
                    <p class="notes">{{model.$.manager_notes}}</p>
                </el-col>
                <el-col :span="10">

                    <el-row class="meta-row">
                        <el-col :span="12">Доставка</el-col>
                        <el-col :span="12" class="text-right">{{model.$.delivery_cost |currency}}</el-col>
                    </el-row>
                    <el-row class="meta-row total">
                        <el-col :span="12">Итого</el-col>
                        <el-col :span="12" class="text-right total-text">{{model.$.total |currency}}</el-col>
                    </el-row>
                </el-col>
            </el-row>
        </section>
    </div>
</template>


<style lang="scss" scoped>
    @import "../../../scss/element";

    section {
        display: block;
        margin: 10px 0 20px;

        .section-header, .total-text {
            /*background-color: silver;*/
            color: #000;
            font-weight: bold;
            font-size: larger;
            padding: 10px;
        }

        .section-header {
            border-bottom: 2px solid #000;
        }

        .section-content {
            padding: 10px;
        }
    }


    .meta-row {
        .el-col {
            padding: 10px;
        }
    }

    .total {
        font-size: larger;
        font-weight: 600;
    }

    .pay-status {
        font-style: italic;
        font-size: smaller;
    }
</style>