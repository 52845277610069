<script>
    /**
     * The html editor
     * @author Evgeny Shevtsov, info@sitespring.ru
     * @homepage https://sitespring.ru
     * @licence Proprietary
     */
    import {quillEditor} from "vue-quill-editor";
    import 'quill/dist/quill.core.css';
    import 'quill/dist/quill.snow.css';

    export default {
        name: "HtmlEditor",
        components: {quillEditor},
        props: {
            value: {
                type: String,
                default: ''
            },
            editorConfig: {
                type: Object
            }
        },

        data() {
            return {
                innerValue: this.value,
                defaultEditorConfig: {
                    placeholder: "Привет мир!",
                    modules: {
                        toolbar: [
                            ['bold', 'italic', 'underline'],
                            [{'list': 'ordered'}, {'list': 'bullet'}],
                            ['clean', 'link']
                        ]
                    },
                }
            }
        },

        computed: {
            innerEditorConfig: vm => ({...vm.defaultEditorConfig, ...vm.editorConfig})
        },

        watch: {
            value: function (val) {
                this.innerValue = val;
            },
            innerValue: function () {
                this.$emit('input', this.innerValue);
            }
        }
    }
</script>


<template>
    <quill-editor v-model="innerValue"
                  class="html-editor"
                  :options="innerEditorConfig"></quill-editor>
</template>


<style lang="scss">
    //default size
    .ql-container {
        height: auto;
        min-height: 150px;
    }
</style>