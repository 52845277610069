<script>
    /**
     * The form`s section manage the client data
     * @author Evgeny Shevtsov, info@sitespring.ru
     * @homepage http://sitespring.ru
     * @licence Proprietary
     */
    import Order from "../../../models/Order";
    import CounterpartyPicker from "../../../components/CounterpartyPicker";

    export default {
        name: "ClientFormSection",
        components: {
            CounterpartyPicker
        },
        props: {
            model: {
                type: Order,
                required: true
            }
        },
        data() {
            return {}
        },
        methods: {
            onCounterpartySelect(record) {
                if (record) {
                    this.model.set({
                        client_name: record.name,
                        client_email: record.email,
                        client_phone: record.phone
                    });
                    if (record.primaryShippingAddress) {
                        this.model.set({
                            delivery_address: record.primaryShippingAddress.address
                        });
                    }
                }
            }
        }
    }
</script>


<template>
    <div>
        <el-row :gutter="20" type="flex" align="bottom">
            <el-col>
                <el-form-item label="Контрагент"
                              :error="model.errors.counterparty_id"
                              :show-message="!!model.errors.counterparty_id">
                    <counterparty-picker v-model="model.counterparty_id"
                                         @modelselect="onCounterpartySelect"></counterparty-picker>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row :gutter="20" type="flex" align="bottom">
            <el-col>
                <el-form-item label="Заказчик"
                              required
                              :error="model.errors.client_name"
                              :show-message="!!model.errors.client_name">
                    <el-input v-model="model.client_name"
                              clearable
                              :maxlength="64"
                              placeholder="Вася Иванов"></el-input>
                </el-form-item>
            </el-col>
            <el-col :md="12">
                <el-form-item label="Email"
                              :error="model.errors.client_email"
                              :show-message="!!model.errors.client_email">
                    <el-input v-model="model.client_email"
                              clearable
                              :maxlength="64"
                              placeholder="ivanov@gmail.com"></el-input>
                </el-form-item>
            </el-col>
            <el-col :md="12">
                <el-form-item label="Телефон"
                              :error="model.errors.client_phone"
                              :show-message="!!model.errors.client_phone">
                    <el-input v-model="model.client_phone"
                              clearable
                              :maxlength="16"
                              placeholder="+71234567890"></el-input>
                </el-form-item>
            </el-col>
        </el-row>

        <el-row v-if="model.$.client_notes"
                :gutter="20" type="flex" align="bottom">
            <el-col>
                <el-form-item label="Примечания клиента"
                              :error="model.errors.client_notes"
                              :show-message="!!model.errors.client_notes">
                    <el-input type="textarea"
                              v-model="model.client_notes"
                              :maxlength="255"
                              :show-word-limit="true"
                              placeholder="Оставьте пустым чтобы удалить..."
                              :autosize="{ minRows: 2}"></el-input>
                </el-form-item>
            </el-col>
        </el-row>
    </div>
</template>


<style scoped>

</style>