<script>
/**
 * @author Evgeny Shevtsov <info@sitespring.ru>
 * @homepage https://sitespring.ru
 * @licence Proprietary, Commercial
 */
import Order from "@/models/Order";
import {parseResponseErrorMessage} from "@/plugins/axios";
import OrderPayment from "@/models/OrderPayment";
import CourierCashboxIncome from "@/models/CourierCashboxIncome";

export default {
    name: "CourierIncomeTimeline",
    props: {
        orderModel: {
            type: Order,
            require: true
        }
    },

    events: [
        /**
         * @event paymentcreated Платеж был создан
         * @param {OrderPayment} $payment
         */
        'paymentcreated'
    ],

    data() {
        return {
            /** @var {BaseStore.<CourierCashboxIncome>} */
            store: [],
            isFormVisible: false,
            formTitle: '',
            /** @var {CourierCashboxIncome} */
            editableRecord: null,
        }
    },

    methods: {
        /**
         * @param {CourierCashboxIncome} $income
         * */
        onForwardClick($income) {
            this.$router.push({
                name: 'courier-cashbox',
                params: {
                    id: $income.courier_id
                }
            });
        },

        /**
         * Выбор опции подтверждения платежа
         * @param {CourierCashboxIncome} $income
         * */
        async onConfirmClick($income) {
            $income.set({
                comment: [$income.comment, `Курьер ${$income.courier_name}`].filter(Boolean).join('. ')
            });
            this.editableRecord = $income;
            this.isFormVisible = true;
            this.formTitle = 'Подтверждение платежа';
        },

        /**
         * Сохраняем данные формы
         * @param {CourierCashboxIncome} $income
         * */
        async onConfirmSaveClick($income) {
            try {
                const $payment = await $income.confirm();
                this.$emit('paymentcreated', $payment);
                // Убираем из списка
                this.onDelete($income);
                this.isFormVisible = false;
                this.editableRecord = null;

                this.$notify({
                    type: 'success',
                    message: `Платеж на сумму ${this.$Formatter.asCurrency($payment.value)} успешно подтвержден`
                });
            } catch (e) {
                this.handleCatchError(e);
            }
        },

        /**
         * Удаляем Платеж
         * @param {CourierCashboxIncome} $income
         * */
        async onDeleteClick($income) {
            try {
                await $income.delete();
                this.onDelete($income);
            } catch (e) {
                this.handleCatchError(e);
            }
        },

        onDelete($income) {
            this.store.remove($income);
            // Заставляем обновиться Вьюшку т.к. метод remove мутирует массив, но не является реактивным
            this.$forceUpdate();
        },

        handleCommand($record, $command) {
            switch ($command) {
                case 'delete':
                    return this.onDeleteClick($record);
                case 'confirm':
                    return this.onConfirmClick($record);
                case 'card':
                default:
                    return this.onForwardClick($record);
            }
        },

        handleCatchError(error) {
            if (error.response) {
                const message = parseResponseErrorMessage(error);
                this.$notify({
                    type: 'error',
                    message
                });
            } else {
                console.error(error);
            }
        },

        async fetchStore() {
            if (this.orderModel) {
                this.store = await CourierCashboxIncome.fetchList(this.orderModel);
            }
        }
    },

    created() {
        this.fetchStore();
    },

    watch: {
        orderModel: (vm) => vm.fetchStore()
    }
}
</script>


<template>
    <div>
        <el-timeline v-if="store.models">
            <el-timeline-item v-for="record in store.models"
                              :key="record.$.id"
                              :timestamp="$Formatter.asDateTime(record.$.created_at)"
                              type="info">
                <el-dropdown trigger="click"
                             @command="handleCommand(record, $event)">
                <span class="action-link">{{ record.typeText }}: {{ record.$.amount|currency }} &nbsp;
                    <small>(Ожидают подтверждения)</small>
                    <i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item command="confirm">
                        <span class="el-link el-link--success">
                            <el-icon class="el-icon-check"></el-icon>
                        Подтвердить поступление платежа
                        </span>
                        </el-dropdown-item>
                        <el-dropdown-item command="card">
                        <span class="el-link el-link--info">
                            <el-icon class="el-icon-user"></el-icon>
                        Перейти в карточку курьера {{ record.$.courier_name }}
                        </span>
                        </el-dropdown-item>
                        <el-dropdown-item command="delete">
                        <span class="el-link el-link--danger">
                            <el-icon class="el-icon-delete"></el-icon>
                        Удалить информацию о поступлении платежа
                        </span>
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>

                <div class="notes">{{ record.$.comment }}</div>
            </el-timeline-item>
        </el-timeline>

        <el-dialog v-if="isFormVisible"
                   :visible.sync="isFormVisible"
                   :v-loading="editableRecord.saving"
                   width="420px"
                   :title="formTitle">
            <el-form>
                <el-row :gutter="20">
                    <el-col :span="24">
                        <el-form-item label="Фактическая дата платежа"
                                      :error="editableRecord.errors.created_at"
                                      :show-message="!!editableRecord.errors.created_at">
                            <el-date-picker v-model="editableRecord.created_at"
                                            type="datetime"
                                            :format="$Formatter.dateTimeDisplayFormat"
                                            :value-format="$Formatter.dateTimeSubmitFormat"></el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-form-item label="Комментарий"
                              :error="editableRecord.errors.comment"
                              :show-message="!!editableRecord.errors.comment">
                    <el-input v-model="editableRecord.comment"
                              type="textarea"
                              show-word-limit
                              :maxlength="255"
                              :rows="{min:3}"></el-input>
                </el-form-item>
            </el-form>
            <div class="toolbar">
                <el-button type="primary" @click="onConfirmSaveClick(editableRecord)">Сохранить</el-button>
            </div>
        </el-dialog>
    </div>
</template>


<style scoped lang="scss">

</style>