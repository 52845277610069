/**
 * @author Evgeny Shevtsov, info@sitespring.ru
 * @homepage http://sitespring.ru
 * @licence Proprietary
 */
import BaseModel from "./BaseModel";
import axios from "axios";
import Order from "@/models/Order";
import BaseStore from "@/models/BaseStore";
import OrderPayment from "@/models/OrderPayment";
import {pick} from "lodash";

export default class CourierCashboxIncome extends BaseModel {
    static entityName = 'cashbox-income';

    defaults() {
        return {
            courier_cachbox_id: null,
            courier_name: null,
            order_id: null,
            box_id: null,
            income_id: null,
            amount: null,
            comment: null,
            created_at: null,
            type: null,
            typeText: ''
        }
    }


    /**
     * Подтверждение платежа от Курьера
     * @return {OrderPayment} Созданный Платеж
     * */
    async confirm() {
        const data = pick(this.attributes, ['created_at', 'comment']);
        // В одном запросе получаем данные по Платежу и Заказу
        const {payment, order} = (await axios.post(`courier/admin/cashbox-income/confirm?token=${this.income_id}`, data)).data;
        const $payment = new OrderPayment(payment);
        // Задаем данные по Заказу
        $payment.getOrder().set(order);
        return $payment;
    }


    /**
     * Удаление платежа от Курьера
     * @return {Promise.<void>}
     * */
    async delete() {
        return await axios.delete(`courier/admin/cashbox-income/${this.income_id}`);
    }


    /**
     * Получение списка ожидающих подтверждения Платежей от Курьера
     * @param {Order} $order Заказ для которого получаем список
     * @return {Promise.<BaseStore<CourierCashboxIncome>>}
     * */
    static fetchList = async ($order) => {
        if (!$order instanceof Order) {
            return Promise.reject("Invalid param type. The Order is expected");
        }
        try {
            let response = await axios.get(`courier/admin/order/${$order.access_token}/income`);
            return new BaseStore(response.data, {
                model: CourierCashboxIncome
            });
        } catch (e) {
            // The courier`s module  does not persist
            if (e.request || e.response && e.response.status === 404) {
                console.log("The courier`s module request failed");
                return null;
            }
            // pass throw
            throw e;
        }
    }
}
