<script>
    /**
     * @author Evgeny Shevtsov, info@sitespring.ru
     * @homepage http://sitespring.ru
     * @licence Proprietary
     */
    import Order from "../../../models/Order";
    import ItemFormSection from "./ItemFormSection";
    import ProductToItemSelect from "./ProductToItemSelect";
    import sumBy from "lodash/sumBy";
    import BaseStore from "../../../models/BaseStore";

    export default {
        name: "ItemsFormSection",
        components: {ItemFormSection, ProductToItemSelect},
        props: {
            model: {
                type: Order,
                required: true
            },
            itemsStore: {
                type: BaseStore,
                required: true
            }
        },
        computed: {
            items: vm => vm.itemsStore.models
        },
        methods: {
            calculateTotal() {
                this.model.total = sumBy(this.items, m => m.total) + this.model.delivery_cost;
            },
            onItemAdd(item) {
                this.itemsStore.add(item);
                this.calculateTotal();
            },
            onItemDelete(item) {
                this.itemsStore.remove(item);
                this.calculateTotal();
            },
            onDeliveryCostChange(newVal, oldVal) {
                this.model.total = this.model.total - (oldVal || 0) + (newVal || 0);
            }
        }
    }
</script>


<template>
    <div>
        <el-row :gutter="5"
                type="flex"
                align="middle"
                class="row-header">

            <el-col :span="1"></el-col>
            <el-col :sm="10">Номенклатура</el-col>
            <el-col :sm="3">Цена</el-col>
            <el-col :sm="4">Кол-во</el-col>
            <el-col :sm="3">Скидка,%</el-col>
            <el-col :sm="3" class="total">Стоимость</el-col>
        </el-row>

        <item-form-section v-for="(item, index) in itemsStore.models"
                           :item="item"
                           :index="index"
                           :errors="model.errors"
                           :key="index"
                           @delete="onItemDelete"
                           @sensitivechange="calculateTotal">
        </item-form-section>

        <el-row :gutter="5"
                type="flex"
                align="middle"
                class="row-item">
            <el-col :span="10" :offset="1">
                <el-form-item label="Добавить позицию"
                              :error="model.errors.items"
                              :show-message="!!model.errors.items">
                    <product-to-item-select @itemselect="onItemAdd"></product-to-item-select>
                </el-form-item>
            </el-col>
        </el-row>

        <el-row :gutter="5" type="flex"
                class="row-total">
            <el-col :span="12">
                <el-form-item label="Примечания"
                              :error="model.errors.manager_notes"
                              :show-message="!!model.errors.manager_notes">
                    <el-input type="textarea"
                              v-model="model.manager_notes"
                              :maxlength="255"
                              :show-word-limit="true"
                              placeholder="Примечания менеджера будут также отображаться в списке заказов..."
                              :autosize="{ minRows: 2}"></el-input>
                </el-form-item>
            </el-col>
            <el-col :span="8" :offset="4">
                <el-row>
                    <el-col :span="12" :offset="12">
                        <el-form-item label="Доставка, руб"
                                      :error="model.errors.delivery_cost"
                                      :show-message="!!model.errors.delivery_cost">
                            <el-input-number v-model="model.delivery_cost"
                                             type="number"
                                             clearable
                                             :controls="false"
                                             placeholder="700.00"
                                             @change="onDeliveryCostChange"
                                             :min="0"></el-input-number>
                        </el-form-item>
                    </el-col>
                </el-row>
                <br>
                <el-row>
                    <el-col :span="12"
                            class="right order-total">Итого:
                    </el-col>
                    <el-col :span="12"
                            class="total order-total">{{model.total|currency}}
                    </el-col>
                </el-row>

            </el-col>
        </el-row>
    </div>
</template>


<style scoped lang="scss">
    .el-form-item {
        margin-bottom: 0 !important;
    }

    .title {

    }

    .total, .right {
        text-align: right;
    }

    .row-header {
        font-size: smaller;
        font-weight: bold;
    }

    .row-header, .row-item {
        margin: 0 0 15px;
    }

    .row-total {
        border-top: 1px solid #000;
        margin: 10px 0 0;
        padding: 10px 0 0;

    }

    .order-total {
        font-size: 18px;
        font-weight: 600;
    }
</style>