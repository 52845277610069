<script>/**
 * @author Evgeny Shevtsov, info@sitespring.ru
 * @homepage http://sitespring.ru
 * @licence Proprietary
 */

import OrderItem from "../../../models/OrderItem";


export default {
    name: "ItemFormSection",
    props: {
        item: {
            type: OrderItem,
            required: true
        },
        index: {
            type: Number,
            required: true
        },
        errors: {
            type: Object,
            default: () => {
            }
        }
    },
    data() {
        return {
            size: 'small'
        }
    },
    methods: {
        onDeleteClick() {
            this.$emit("delete", this.item);
        },
        onSensitiveChange() {
            this.item.total = this.item.cost * this.item.count * (1 - this.item.discount_percent / 100);
            this.$emit("sensitivechange", this.item);
        }
    }
}
</script>


<template>
    <el-row :gutter="5"
            type="flex"
            align="middle"
            class="row-item">
        <el-col :span="1">
            <el-button type="danger"
                       circle
                       size="mini"
                       @click="onDeleteClick"
                       icon="el-icon-delete"></el-button>
        </el-col>
        <el-col :sm="10">
            <el-form-item :size="size"
                          :error="errors[`batchItem[${index}][title]`]"
                          :show-message="!!errors[`batchItem[${index}][title]`]">
                <el-input v-model="item.title"
                          clearable></el-input>
            </el-form-item>
        </el-col>
        <el-col :sm="3">
            <el-form-item :size="size"
                          :error="errors[`batchItem[${index}][cost]`]"
                          :show-message="!!errors[`batchItem[${index}][cost]`]">
                <el-input-number v-model="item.cost"
                                 clearable
                                 :min="1"
                                 @change="onSensitiveChange"
                                 :controls="false"></el-input-number>
            </el-form-item>
        </el-col>
        <el-col :sm="4">
            <el-form-item :size="size"
                          :error="errors[`batchItem[${index}][count]`]"
                          :show-message="!!errors[`batchItem[${index}][count]`]">
                <el-input-number v-model="item.count"
                                 @change="onSensitiveChange"
                                 :min="1"></el-input-number>
            </el-form-item>
        </el-col>
        <el-col :sm="3">
            <el-form-item :size="size"
                          :error="errors[`batchItem[${index}][discount_percent]`]"
                          :show-message="!!errors[`batchItem[${index}][discount_percent]`]">
                <el-input-number v-model="item.discount_percent"
                                 :controls="false"
                                 :min="0"
                                 :max="100"
                                 @change="onSensitiveChange"
                                 type="number"></el-input-number>
            </el-form-item>
        </el-col>
        <el-col :sm="3"
                class="total">
            {{item.total|currency}}
        </el-col>
    </el-row>
</template>


<style lang="scss" scoped>
    .title {
    }

    .total {
        text-align: right;
    }

    .el-form-item {
        margin-bottom: 0 !important;
    }
</style>