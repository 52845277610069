<script>
/**
 * @author Evgeny Shevtsov, info@sitespring.ru
 * @homepage http://sitespring.ru
 * @licence Proprietary
 */
import Overview from "./Overview";
import OrderForm from "../form/Form";
import Order from "../../../models/Order";
import InlineAlert from "../../../components/InlineAlert";
import OrderPdfPrinterWrapper from "@/models/OrderPdfPrinterWrapper.js";

export default {
    name: "Basic",
    components: {
        InlineAlert,
        Overview,
        OrderForm
    },
    props: {
        model: {
            type: Order,
            required: true
        }
    },
    data() {
        return {
            isLoading: false,
            isEditMode: false,
            printModel: null,
            isLegal: false
        }
    },
    computed: {
        isConfirmationRequired: vm => vm.model.isConfirmationRequired,
        printButtonText: vm => !vm.isLegal ? "Товарный чек" : "Товарная накладная ТОРГ-12"
    },
    methods: {
        updateIsLegal() {
            this.isLegal = this.model.getCounterparty().getIsLegal();
        },
        onEditClick() {
            this.isEditMode = true;
        },
        onCancelClick() {
            this.isEditMode = false;
        },
        onPrintClick() {
            this.isLoading = true;
            let printer = OrderPdfPrinterWrapper.createPrinter(this.model);
            printer.printPdf();
            this.isLoading = false;
        },

        async onSendToCustomerClick() {
            try {
                await this.model.actionSendToCustomer();
                this.$message.success("Номенклатура успешно отправлена покупателю");
            } catch (e) {
                this.$message.error(e.message || 'Ошибка запроса');
            }
        }
    },

    created() {
        // After the model is saved successfully, where the model was updated.
        this.model.on("fetch, update", this.updateIsLegal);
        this.updateIsLegal();
    }
}
</script>


<template>
    <div v-loading="isLoading">
        <div v-if="!isEditMode">
            <div class="toolbar">
                <el-button icon="el-icon-edit"
                           size="small"
                           @click="onEditClick">Редактировать
                </el-button>
                <div class="grow">&nbsp;</div>
                <el-tooltip content="Будет сформирован pdf документ и выведен на печать в отдельной вкладке">
                    <el-button icon="el-icon-printer"
                               size="small"
                               @click="onPrintClick">{{ printButtonText }}
                    </el-button>
                </el-tooltip>
                <el-popconfirm title="Покупателю будет отправлено дополнительное подтверждающее письмо с актуальной номенклатурой"
                               cancel-button-text="Не надо, я случайно"
                               confirm-button-text="Отправить"
                               @confirm="onSendToCustomerClick">
                    <el-button icon="el-icon-message"
                               slot="reference"
                               size="small">Отправить покупателю
                    </el-button>
                </el-popconfirm>
            </div>
            <overview :model="model"></overview>
        </div>

        <div v-else>
            <order-form :model="model"
                        @cancel="onCancelClick"></order-form>
        </div>
    </div>
</template>


<style scoped lang="scss">

</style>