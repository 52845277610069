<script>
    /**
     * The primary action selector button
     * @author Evgeny Shevtsov, info@sitespring.ru
     * @homepage https://sitespring.ru
     * @licence Proprietary
     */
    import Order from "../../../models/Order";
    import BasicsForm from "../../product/BasicsForm";
    import StatusChangeDialog from "./StatusChangeDialog";
    import filter from "lodash/filter";
    import values from "lodash/values";

    // const list of keys
    class ActionKeys {
        static CONFIRM = 'confirm';
        static DELIVERY = 'delivery';
        static RECEIVED = 'received';
        static CANCELED = 'canceled';
        static STATUS = 'status';
    };

    export default {
        name: "PrimaryActionButton",
        components: {StatusChangeDialog, BasicsForm},
        props: {
            orderModel: {
                type: Order,
                required: true
            }
        },
        data() {
            return {
                isStatusEdit: false,
                actions: {
                    [ActionKeys.CONFIRM]: {
                        label: 'Подтвердить',
                        icon: 'el-icon-box',
                        handler: 'onConfirm',
                        type: 'primary'
                    },
                    [ActionKeys.DELIVERY]: {
                        label: 'Передать в доставку',
                        icon: 'el-icon-truck',
                        handler: 'onDelivery',
                        type: 'info'
                    },
                    [ActionKeys.RECEIVED]: {
                        label: 'Получен покупателем',
                        icon: 'el-icon-s-custom',
                        handler: 'onReceive',
                        type: 'primary'
                    },
                    [ActionKeys.STATUS]: {
                        label: 'Изменить статус',
                        icon: 'el-icon-edit',
                        handler: 'onStatusEdit',
                        type: 'default'
                    },
                    [ActionKeys.CANCELED]: {
                        label: 'Отменить',
                        icon: 'el-icon-s-release',
                        handler: 'onCancel',
                        type: 'danger'
                    },
                }
            }
        },

        computed: {
            currentPrimaryActionKey: (vm) => {
                switch (vm.orderModel.status) {
                    case Order.STATUS_CREATED:
                        return ActionKeys.CONFIRM;
                    case Order.STATUS_CONFIRM:
                        return ActionKeys.DELIVERY;
                    case Order.STATUS_DELIVERING:
                        return ActionKeys.RECEIVED;
                    default:
                        return ActionKeys.STATUS;
                }
            },
            currentPrimaryAction: vm => vm.actions[vm.currentPrimaryActionKey],
            actionList: (vm) => {
                return filter(vm.actions, (action, key) => key !== vm.currentPrimaryActionKey);
            }
        },

        methods: {
            async onCommand(command) {
                try {
                    await this[command].call(this, {
                        params: {
                            fields: 'status,updated_at',
                            expand: 'statusText'
                        }
                    });
                } catch (e) {
                    let msg = e.message || 'Ошибка запроса';
                    if (this.orderModel.isBackendValidationError(e)) {
                        msg = values(this.orderModel.errors)[0];
                    }
                    this.$message.error(msg);
                }
            },
            async onConfirm(extraParams = {}) {
                await this.orderModel.actionConfirm(extraParams);
                this.$message.success('Заказ успешно подтвержден');
            },
            async onDelivery(extraParams = {}) {
                await this.orderModel.actionSendToDelivery(extraParams);
                this.$message.success("Заказ передан для доставки");
            },
            async onReceive(extraParams = {}) {
                await this.orderModel.actionCustomerReceive(extraParams);
                this.$message.success("Заказ получен покупателем");
            },
            async onCancel(extraParams = {}) {
                await this.orderModel.actionCancel(extraParams);
                this.$message.success("Заказ отменен");
            },
            async onStatusEdit() {
                this.isStatusEdit = true;
            }
        }
    }
</script>


<template>
    <div class="toolbar">
        <el-dropdown trigger="click"
                     :type="currentPrimaryAction.type"
                     size="medium"
                     split-button
                     :icon="currentPrimaryAction.icon"
                     @click="onCommand(currentPrimaryAction.handler)"
                     @command="onCommand($event)">
            <el-icon :class="currentPrimaryAction.icon"></el-icon>
            {{currentPrimaryAction.label}}
            <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-for="(action,key) in actionList"
                                  :key="key"
                                  :command="action.handler">
                    <span :class="`el-link el-link--${action.type}`">
                        <el-icon class="command-icon" :class="action.icon"></el-icon>
                        {{action.label}}
                    </span>
                </el-dropdown-item>
            </el-dropdown-menu>
        </el-dropdown>

        <status-change-dialog v-if="isStatusEdit"
                              @close="isStatusEdit=false"
                              append-to-body
                              :order-model="orderModel"></status-change-dialog>
    </div>
</template>


<style scoped lang="scss">
    .toolbar {
        display: inline-flex;
        margin: 0;
    }

    .command-icon {
        font-size: 1.3rem;
    }

    .el-link {
        padding: .5rem 0;
    }
</style>