<script>
    import Order from "../../../models/Order";
    import OrderStatusPicker from "../StatusPicker";

    /**
     * @author Evgeny Shevtsov, info@sitespring.ru
     * @homepage https://sitespring.ru
     * @licence Proprietary
     */


    export default {
        name: "StatusChangeDialog",
        components: {OrderStatusPicker},
        props: {
            orderModel: {
                type: Order,
                required: true
            }
        },
        data() {
            return {
                isVisible: true,
                innerValue: this.orderModel.status
            }
        },
        methods: {
            async onSubmitClick() {
                try {
                    await this.orderModel.actionStatusChange(this.innerValue, {
                        params: {
                            fields: 'updated_at,status',
                            expand: 'statusText'
                        }
                    });
                    this.isVisible = false;
                    this.$message.success('Статус изменен');
                } catch (e) {
                    if (!this.orderModel.isBackendValidationError(e)) {
                        this.$message.error(e.message || 'Ошибка');
                    }
                }
            }
        }
    }
</script>


<template>
    <el-dialog :visible.sync="isVisible"
               v-bind="$attrs"
               v-on="$listeners"
               title="Сменить статус вручную">
        <el-form>
            <el-form-item label="Новый статус"
                          :error="orderModel.errors.status"
                          :show-message="!!orderModel.errors.status">
                <order-status-picker v-model="innerValue"
                                     :is-single="true"></order-status-picker>
            </el-form-item>
        </el-form>
        <template slot="footer" class="text-left">
            <el-button type="danger"
                       @click="isVisible=false">Отмена
            </el-button>
            <el-button type="primary"
                       @click="onSubmitClick">Изменить
            </el-button>
        </template>
    </el-dialog>
</template>


<style scoped>

</style>