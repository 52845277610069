<script>
    /**
     * The order form
     * @author Evgeny Shevtsov, info@sitespring.ru
     * @homepage http://sitespring.ru
     * @licence Proprietary
     */
    import BaseForm from "../../../components/Form";
    import Order from "../../../models/Order";
    import ClientFormSection from "./ClientFormSection";
    import DeliveryFormSection from "./DeliveryFormSection";
    import ItemsFormSection from "./ItemsFormSection";

    export default {
        name: "Form",
        components: {
            ItemsFormSection,
            ClientFormSection,
            DeliveryFormSection,
            BaseForm
        },
        props: {
            model: {
                type: Order,
                default: () => new Order()
            }
        },
        data() {
            return {
                isEdit: this.model.id,
                title: this.model.id ? '' : 'Новый заказ',
                itemsStore: this.model.getItems(),
                btnSaveText: this.model.id ? "Сохранить" : "Создать"
            }
        },
        methods: {
            onCancelClick() {
                if (this.isEdit) {
                    // popup to parent
                    this.$emit("cancel");
                } else {
                    // handle by self
                    this.$router.back();
                }
            },
            async onUpdateClick() {
                let order = this.model;
                try {
                    // update items with saved batch
                    order.set("items", this.itemsStore.toJSON());
                    await order.save({
                        params: {
                            expand: 'deliveryText,counterparty,items,courierName,number,payStatusText'
                        }
                    });

                    this.$message.success(this.isEdit ? "Изменения успешно сохранены" : `Заказ успешно создан под номером #${order.number}`);

                    // notify parent about success saving action
                    this.onCancelClick();
                } catch (e) {
                    if (order.fatal) {
                        this.$message.error("Ошибка запроса");
                    }
                }
            },
            async onDeleteConfirm() {
                let order = this.model;
                try {
                    await order.delete();
                    this.$message.success(`Заказ успешно удален`);
                    this.$router.push({name: 'order-list'});
                } catch (e) {
                    if (order.fatal) {
                        this.$message.error("Ошибка запроса");
                    }
                }
            }
        }
    }
</script>


<template>
    <base-form class="humanize-container--long"
               :auto-load="false"
               :model="model"
               :title="title">
        <el-row :gutter="50" type="flex" align="top">
            <el-col :span="12">
                <client-form-section :model="model"></client-form-section>
            </el-col>
            <el-col :span="12">
                <delivery-form-section :model="model"></delivery-form-section>
            </el-col>
        </el-row>

        <div>
            <items-form-section :model="model"
                                :items-store="itemsStore"></items-form-section>
        </div>

        <template v-slot:edit-toolbar="">
            <div class="toolbar">
                <el-button @click="onCancelClick">Отменить
                </el-button>
                <el-button type="primary"
                           :loading="model.saving"
                           @click="onUpdateClick">{{btnSaveText}}
                </el-button>
                <div v-if="isEdit" class="grow" align="right">
                    <el-popconfirm
                            title="Заказ будет помечен как удаленный и будет скрыт... Уверены что хотите продолжить?"
                            cancel-button-type="info"
                            cancel-button-text="Не удалять"
                            confirm-button-text="Все равно удалить"
                            confirm-button-type="text"
                            @confirm="onDeleteConfirm">
                        <el-button slot="reference"
                                   type="danger"
                                   icon="el-icon-delete">Удалить
                        </el-button>
                    </el-popconfirm>
                </div>
            </div>
        </template>
    </base-form>
</template>


<style scoped>
</style>