<script>
    /**
     * The inline alert component
     * @author Evgeny Shevtsov, info@sitespring.ru
     * @homepage https://sitespring.ru
     * @licence Proprietary
     */

    export default {
        name: "InlineAlert",
        props: {
            /*
            * Enum of (default|warning|danger)
            * */
            type: {
                type: String,
                default: 'default'
            }
        },
        computed: {
            extraClsName: (vm) => `alert--${vm.type}`
        }
    }
</script>


<template>
    <div class="alert"
         :class="extraClsName">
        <slot name="default"></slot>
    </div>
</template>


<style lang="scss">
    @import "../scss/vars.scss";

    .alert {
        padding: 10px;
        border-radius: 5px;
        // default
        background-color: #d8e0e5;
    }

    // make color classes
    @each $name, $color in $service-colors {
        .alert--#{$name} {
            background-color: lighten($color, 0%);
        }
    }

    @each $name, $color in $service-alternate-colors {
        .alert--#{$name} {
            color: $color;
        }
    }
</style>