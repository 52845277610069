<template>
  <store-filters-pane v-bind="$props">
    <template v-slot:default>
      <el-form label-position="top"
               ref="filtersForm"
               class="content">

        <el-form-item label="Доставка курьером">
          <courier-select v-model="courierIDs"
                          multiple
                          :collapse-tags="false"
                          placeholder="Любой курьер..."
                          clearable>
          </courier-select>
        </el-form-item>
        <el-form-item label="Статус заказа">
          <order-status-picker v-model="statuses"
                               :collapse-tags="false">
          </order-status-picker>
        </el-form-item>
        <el-form-item label="Контрагент">
          <counterparty-picker v-model="counterparties"
                               :multiple="true"></counterparty-picker>
          <el-checkbox v-model="hasEmptyCounterparty">Без привязки к контрагенту</el-checkbox>
        </el-form-item>


      </el-form>
    </template>
  </store-filters-pane>
</template>

<script>
import StoreFiltersPane from "@/components/StoreFiltersPane";
import BaseStore from "@/models/BaseStore";
import CourierSelect from "@/components/CourierSelect";
import OrderStatusPicker from "@/views/order/StatusPicker";
import CounterpartyPicker from "@/components/CounterpartyPicker";

/**
 * Панель фильтров для Списка Заказов
 *
 * @author Evgeny Shevtsov <info@sitespring.ru>
 * @homepage https://sitespring.ru
 * @licence Proprietary, Commercial
 */
export default {
  name: "ListStoreFilters",
  components: {
    CounterpartyPicker,
    StoreFiltersPane,
    CourierSelect,
    OrderStatusPicker
  },
  props: {
    store: {
      type: BaseStore,
      require: true
    }
  },
  data() {
    return {
      sort: null,
      courierIDs: [],
      statuses: [],
      counterparties: [],
      hasEmptyCounterparty: false,
      sortOptions: [
        {
          id: 'created_desc',
          title: 'Недавно созданные',
          property: 'created_at',
          direction: BaseStore.SORT_DESC
        },
        {
          id: 'updated_desc',
          title: 'Недавно измененные',
          property: 'updated_at',
          direction: BaseStore.SORT_DESC
        }
      ]
    }
  },
  watch: {
    courierIDs(ids) {
      this.store.applyFilter('couriers', {
        property: 'courier_id',
        operator: 'in',
        value: ids
      });
    },
    statuses(statuses) {
      this.store.applyFilter('statuses', {
        property: 'status',
        operator: 'in',
        value: statuses
      });
    },
    counterparties(ids) {
      this.store.applyFilter('counterparty', {
        property: 'counterparty_id',
        operator: 'in',
        value: ids
      });
    },
    hasEmptyCounterparty(val) {
      if (val) {
        this.store.applyFilter('hasEmptyCounterparty', {
          property: 'counterparty_id',
          operator: 'null'
        });
      } else {
        this.store.removeFilter('hasEmptyCounterparty')
      }
    }
  },
  methods: {
    applyCurrentFilters() {
      this.courierIDs = [];
      this.statuses = [];
      this.counterparties = [];
      this.hasEmptyCounterparty = false;
    }
  },
  created() {
    this.applyCurrentFilters();
    this.store.on(BaseStore.EVENT_FILTERSDROP, this.applyCurrentFilters);
  }
}
</script>

<style lang="scss">

</style>