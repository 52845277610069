<script>
/**
 * @author Evgeny Shevtsov, info@sitespring.ru
 * @homepage http://sitespring.ru
 * @licence Proprietary
 */
import Order from "../../../models/Order";
import Formatter from "../../../utils/Formatter";
import OrderHistory from "../../../models/OrderHistory";
import compact from "lodash/compact";
import filter from "lodash/filter";

export default {
  name: "Balance",
  props: {
    model: {
      type: Order,
      required: true
    }
  },
  data() {
    return {
      Formatter,
      isTrackingOnly: false,
      history: this.model.getHistory({
        autoLoad: true,
        fetchParams: {
          limit: 0,
          sort: '-created_at,-id'
        }
      })
    }
  },
  computed: {
    onlyTrackHistory: (vm) => filter(vm.history.models, {is_tracking: 1}),
    displayHistory: (vm) => vm.isTrackingOnly ? vm.onlyTrackHistory : vm.history.models
  },

  methods: {
    getColor(item) {
      if (item.subcategory === 'danger') {
        return 'red';
      }
      if (item.is_tracking) {
        return 'darkgreen';
      }
      switch (item.category) {
        case OrderHistory.CATEGORY_NOTIFICATION:
          return '#999';
        case OrderHistory.CATEGORY_MANAGER_EDIT:
          return '#333';
        default:
          return '#ccc';
      }
    },
    getSize(item) {
      if (item.is_tracking) {
        return 'large';
      }
      return 'normal';
    },
    getIcon(item) {
      if (item.is_tracking) {
        return 'el-icon-s-flag';
      }
      switch (item.category) {
        case OrderHistory.CATEGORY_SYSTEM_EDIT:
          return 'el-icon-s-tools';
        case OrderHistory.CATEGORY_MANAGER_EDIT:
          return 'el-icon-user-solid';
        case OrderHistory.CATEGORY_NOTIFICATION:
          return 'el-icon-message-solid';
        default:
          return '';
      }
    },
    getSubcategoryIcon(item) {
      switch (item.subcategory) {
        case OrderHistory.SUBCATEGORY_PAYMENT:
          return 'el-icon-money';
        default:
          return '';
      }
    },
    getTimestamp(item) {
      return compact([
        Formatter.asDateTime(item.$.created_at),
        (item.category !== OrderHistory.CATEGORY_SYSTEM_EDIT
            && item.category !== OrderHistory.CATEGORY_NOTIFICATION
            && item.created_name) ? item.created_name : null
      ]).join(", ");
    }
  },

  created() {
    // refresh log history if model has been modified
    this.model.on('update', () => {
      this.history.reload();
    });
  },

  destroyed() {
    //stop to listen
    delete this.model.off('update');
  }
}
</script>


<template>
  <div>
    <div class="toolbar">
      <el-switch v-model="isTrackingOnly"
                 active-text="Краткий вид"></el-switch>
    </div>
    <el-timeline>
      <el-timeline-item v-for="record in displayHistory"
                        :key="record.id"
                        :size="getSize(record)"
                        :color="getColor(record)"
                        :icon="getIcon(record)"
                        :timestamp="getTimestamp(record)"
                        type="default">
                <span :style="{color: getColor(record)}"
                      class="title"
                      :class="`title-${getSize(record)}`">{{ record.message }}</span>
      </el-timeline-item>
    </el-timeline>
  </div>

</template>


<style scoped lang="scss">
.dot {
  border: 1px solid red;
  background: #fff;
  padding: .1em 0;
}

.title {
  display: inline-block;

  &-large {
    margin-top: .2rem;
    font-size: 1.1rem;
  }
}
</style>